import {
  Box,
  Stack,
  Typography
} from '@mui/material';
import { useRouter } from 'next/router';
import Back from 'public/assets/icons/back.svg';
import NewPasswordIcon from 'public/assets/icons/new-password.svg';
import { newPasswordDesc } from '~config/constants/texts';
import AuthNewPasswordForm from './components/AuthNewPasswordForm';

function NewPassword({
  onLogin,
  ...rest
}: any) {

  const {
    push
  } = useRouter()

  const handleLogin = () => {
    if (onLogin)
      onLogin()
    else
      push('./login')
  }

  return (
    <Stack
      sx={{
        border: '0px solid red',
        maxWidth: '480px',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <NewPasswordIcon
        height={75}
        width={100}
      />
      <Typography
        sx={{
          fontSize: '35px !important',
          mt: 8
        }}
        variant="h3"
        paragraph
      >
        Request sent successfully!
      </Typography>
      <Typography
        sx={{
          color: 'text.secondary',
          mb: 5,
          fontSize: '18px',
        }}
      >
        {newPasswordDesc}
      </Typography>
      <AuthNewPasswordForm
        {...rest}
      />
      <Box
        onClick={handleLogin}
        sx={{
          mt: 3,
          mx: 'auto',
          alignItems: 'center',
          display: 'inline-flex',
          cursor: 'pointer',
        }}
      >
        <Back
          height={12}
          width={7}
        />
        <Typography
          sx={{
            ml: 1
          }}
        >
          Return to sign in
        </Typography>
      </Box>
    </Stack>
  );
}

export default NewPassword