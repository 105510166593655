import AuthModalLayout from '~layouts/auth-modal-layout';
import Register from '~screens/auth/Register';
import { IMAGES_PREFIX } from "~config/config-global"

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useModalDialogs } from '~screens/shared/modal-dialogs/modal-dialogs-provider';

type RegisterModalType = {
    open: boolean,
    setOpen: any,
    setLoginModal: any,
    forbidClose?: boolean,
}

function RegisterModal({
    open,
    setOpen,
    forbidClose,
    setLoginModal,
}: RegisterModalType) {

    const { triggerRegistrationSuccessCallback } = useModalDialogs();

    const onLogin = () => {
        setOpen(false)
        setLoginModal(true)
    }

    return (
        <AuthModalLayout
            open={open}
            setOpen={setOpen}
            forbidClose={forbidClose}
            leftImage={
                IMAGES_PREFIX + '/assets/images/register-modal.png'
            }
        >
            <IconButton
                sx={{
                    position: 'absolute',
                    top: 21,
                    right: 16,
                    cursor: 'pointer',
                    zIndex: 10,
                }}
                onClick={() => setOpen(false)}
            >
                <CloseIcon />
            </IconButton>

            <Register
                onLogin={onLogin}
                onRegisterSuccess={() => {
                    setOpen(false)
                    triggerRegistrationSuccessCallback();
                }
                }
            />
        </AuthModalLayout>
    );
}

export default RegisterModal