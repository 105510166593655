import {
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import {
  useRouter
} from 'next/router';
import useResponsive from '~hooks/useResponsive';
import AuthLoginForm from './components/AuthLoginForm';

function Login({
  onRegister,
  ...rest
}: any) {

  const {
    palette: {
      text,
    }
  }: any = useTheme()

  const {
    push
  } = useRouter()

  const isMobile
    = useResponsive('down', 'md');

  const handleRegister = () => {

    if (onRegister)
      onRegister()
    else
      push('./signup')

  }

  return (
    <>
      <Stack
        spacing={2}
        sx={{
          mb: 5,
          border: '0px solid red',
          alignItems: isMobile ?
            'center'
            :
            undefined
        }}
      >
        <Typography
          variant={isMobile ?
            "h3"
            :
            "h4"
          }
        >
          Log in to your account
        </Typography>
        <Typography
          onClick={handleRegister}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            border: '0px solid red',
            maxWidth: '95%',
            width: '25em',
          }}
        >
          New user?

          {' '}
          <Typography
            component='span'
            sx={{
              pl: '1em',
              textDecoration: 'underline'
            }}
          >
            Register
          </Typography>

        </Typography>
        <Typography
          sx={{
            ml: 1,
            color: text.link
          }}
        >
        </Typography>
      </Stack>
      <AuthLoginForm
        {...rest}
      />
    </>
  );
}

export default Login