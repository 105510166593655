import { useDispatch } from 'react-redux';
import AuthModalLayout from '~layouts/auth-modal-layout';
import ResetPassword from '~screens/auth/ResetPassword';
import { setResetPasswordProps } from '~screens/auth/store/authSlice';
import { IMAGES_PREFIX } from "~config/config-global"

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type ResetPasswordModalType = {
    open: boolean,
    setOpen: any,
    setLoginModal: any,
    setNewPasswordModal: any,
    forbidClose?: boolean,
}

function ResetPasswordModal({
    open,
    setOpen,
    forbidClose,
    setLoginModal,
    setNewPasswordModal,
}: ResetPasswordModalType) {

    const dispatch
        = useDispatch()

    const onLogin = () => {
        setOpen(false)
        setLoginModal(true)
    }
    const onSuccess = (
        email: string,
        userId: string,
    ) => {

        dispatch(
            setResetPasswordProps({ email, userId })
        )
        setOpen(false)
        setNewPasswordModal(true)
    }

    return (
        <AuthModalLayout
            forbidClose={forbidClose}
            open={open}
            setOpen={setOpen}
            leftImage={
                IMAGES_PREFIX + '/assets/images/reset-password-modal.png'
            }
        >


            <IconButton
                sx={{
                    position: 'absolute',
                    top: 21,
                    right: 16,
                    cursor: 'pointer',
                    zIndex: 10,
                }}
                onClick={() => setOpen(false)}
            >
                <CloseIcon />
            </IconButton>


            <ResetPassword
                onLogin={onLogin}
                onSuccess={onSuccess}
            />
        </AuthModalLayout>
    );
}

export default ResetPasswordModal