import {
    ButtonBase,
    CircularProgress,
    Typography,
    useTheme
} from '@mui/material'
import { useSearchParams } from 'next/navigation'
import { toast } from 'react-hot-toast'
import { useSendOtpMutation } from '../services/authApi'
import { useSelector } from 'react-redux'

function ResendCode({
}) {

    const {
        palette: {
            text,
        }
    }: any = useTheme()

    const searchParams: any = useSearchParams()

    const [
        sendOtp,
        { isLoading }
    ] = useSendOtpMutation()

    const {
        resetPasswordProps,
    } = useSelector((
        state: any
    ) => state.auth)

    const handleResendCode = async () => {

        const response
            = await sendOtp(
                searchParams.get('userId')
                ||
                resetPasswordProps?.userId
            )

        const {
            error,
            data: respData
        }: any
            = response || {}

        if (error)
            toast.error(error?.data?.message)

        if (respData)
            toast.success(respData?.message)
    }

    return (
        <Typography
            sx={{
                cursor: 'pointer',
                display: 'flex',
                border: '0px solid red',
                justifyContent: 'center',
                marginTop: '12px'
            }}

        >
            Didn't get the code?
            <ButtonBase
                onClick={handleResendCode}
                sx={{
                    borderRadius: 1,
                    width: 105,
                }}
                disabled={isLoading}
            >
                {isLoading ?
                    <CircularProgress
                        size={15}
                    />
                    :
                    <Typography
                        sx={{
                            ml: 1,
                            color: text.link
                        }}
                    >
                        Resend code
                    </Typography>
                }
            </ButtonBase>
        </Typography>
    )
}

export default ResendCode