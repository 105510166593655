import {
    useDispatch
} from 'react-redux';
import AuthModalLayout from '~layouts/auth-modal-layout';
import NewPassword from '~screens/auth/NewPassword';
import {
    setResetPasswordProps
} from '~screens/auth/store/authSlice';
import { IMAGES_PREFIX } from "~config/config-global"

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type NewPasswordModalType = {
    open: boolean,
    setOpen: any,
    setLoginModal: any,
    forbidClose?: boolean,
}

function NewPasswordModal({
    open,
    setOpen,
    setLoginModal,
    forbidClose,
}: NewPasswordModalType) {

    const dispatch
        = useDispatch()

    let onLogin = () => {

        setOpen(false)
        setLoginModal(true)
    }

    const onSuccess = () => {
        onLogin()
        dispatch(
            setResetPasswordProps(
                null
            )
        )
    }

    return (
        <AuthModalLayout
            forbidClose={forbidClose}
            open={open}
            setOpen={setOpen}
            leftImage={
                IMAGES_PREFIX + '/assets/images/new-password-modal.png'
            }
        >

            <IconButton
                sx={{
                    position: 'absolute',
                    top: 21,
                    right: 16,
                    cursor: 'pointer',
                    zIndex: 10,
                }}
                onClick={() => setOpen(false)}
            >
                <CloseIcon />
            </IconButton>

            <NewPassword
                onLogin={onLogin}
                onSuccess={onSuccess}
            />
        </AuthModalLayout>
    );
}

export default NewPasswordModal