import React, { useState } from 'react';
import { Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import LoginModal from "~components/login-modal";
import NewPasswordModal from "~components/new-password-modal";
import RegisterModal from "~components/register-modal";
import ResetPasswordModal from "~components/reset-password-modal";
import { useModalDialogs } from './modal-dialogs-provider';

const LoginModals = () => {

    const { user } = useSelector((state: any) => state.auth);

    const { isLoginDialogOpen, isRegisterDialogOpen, showLoginDialog, showRegisterDialog, hideDialogs } = useModalDialogs();

    const [newPasswordModal, setNewPasswordModal] = useState(false);
    const [resetPasswordModal, setResetPasswordModal] = useState(false);

    if (user?.userDto?.role?.name !== "Visitor" && user) return null;

    return (
        <>
            <NewPasswordModal
                open={newPasswordModal}
                setOpen={setNewPasswordModal}
                setLoginModal={showLoginDialog}
            />
            <ResetPasswordModal
                open={resetPasswordModal}
                setLoginModal={showLoginDialog}
                setOpen={setResetPasswordModal}
                setNewPasswordModal={setNewPasswordModal}
            />
            <RegisterModal
                open={isRegisterDialogOpen}
                setOpen={hideDialogs}
                setLoginModal={showLoginDialog}
            />
            <LoginModal
                open={isLoginDialogOpen}
                setOpen={hideDialogs}
                setRegisterModal={showRegisterDialog}
                setResetPasswordModal={setResetPasswordModal}
            />
        </>
    );
}

export default LoginModals;


/*

import { useModalDialogs } from '../../shared/modal-dialogs/modal-dialogs-provider';
import LoginModals from '../../shared/modal-dialogs/login-modals.tsx';

function ParentComponent() {
    const { showLoginDialog, showRegisterDialog } = useModalDialogs();

    return (
        <div>
            <button onClick={showLoginDialog}>Show Login</button>
            <button onClick={showRegisterDialog}>Show Register</button>
            <LoginModals  />
        </div>
    );
}

export default ParentComponent;

*/
