import {
  Box
} from '@mui/material'
import {
  useSearchParams
} from 'next/navigation'
import {
  useRouter
} from 'next/router'
import {
  useEffect
} from 'react'
import {
  useForm
} from 'react-hook-form'
import {
  toast
} from 'react-hot-toast'
import { useSelector } from 'react-redux'
import Button from '~components/Button/Button'
import FormProvider, {
  RHFTextField
} from '~components/hook-form'
import useResponsive from '~hooks/useResponsive'
import newPasswordSchema from '../schema/new-password.schema'
import {
  useResetPasswordMutation
} from '../services/authApi'
import ResendCode from './ResendCode'

function AuthNewPasswordForm({
  onSuccess,
}: any) {

  const {
    push
  } = useRouter()

  const isMobile
    = useResponsive('down', 'md')

  const sx = {
    mb: '30px',
    width: !isMobile ?
      '450px'
      :
      undefined
  }

  const searchParams: any
    = useSearchParams()
  const email
    = searchParams.get('email')

  const [
    resetPassword,
    { isLoading }
  ] = useResetPasswordMutation()

  const {
    resetPasswordProps,
  } = useSelector((
    state: any
  ) => state.auth)

  const methods
    = useForm({
      mode: 'onChange',
      resolver: newPasswordSchema,
    })

  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = methods

  const watchEmail
    = watch('email')

  const handleUpdatePassword
    = handleSubmit(async data => {

      const response
        = await resetPassword(
          data
        )

      const {
        error,
        data: respData
      }: any
        = response || {}

      if (error)
        toast.error(
          error
            ?.data
            ?.message
        )

      if (respData) {

        toast.success(respData?.message)
        if (onSuccess)
          onSuccess()
        else
          push('/login')
      }

    })

  useEffect(() => {

    if (
      email
      &&
      !resetPasswordProps?.email
    )
      reset({
        email
      })
  }, [email])

  useEffect(() => {

    if (resetPasswordProps?.email)
      reset({
        email:
          resetPasswordProps?.email
      })
  }, [resetPasswordProps?.email])

  useEffect(() => {

    if (email)
      reset({
        email
      })
  }, [email])

  return (
    <FormProvider
      methods={methods}
    >
      <Box
        sx={{
          mx: 2
        }}
      >
        <RHFTextField
          name="email"
          label="Email"
          InputLabelProps={{
            shrink: true
          }}
          sx={sx}
          disabled={watchEmail}
        />
        <RHFTextField
          name="newPassword"
          label="New Password"
          sx={sx}
        />
        <RHFTextField
          name="confirmPassword"
          label="Confirm New Password"
          sx={sx}
        />
        <RHFTextField
          name="code"
          label="One Time Code"
          sx={sx}
        />

        <Button
          loading={isLoading}
          onClick={handleUpdatePassword}
          title='Update Password'
        />

        <ResendCode />
      </Box>
    </FormProvider>
  )
}

export default AuthNewPasswordForm