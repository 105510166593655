import AuthModalLayout from '~layouts/auth-modal-layout';
import Login from '~screens/auth/Login';
import { IMAGES_PREFIX } from "~config/config-global"

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type LoginModalType = {
    open: boolean,
    setOpen: any,
    setRegisterModal: any,
    setResetPasswordModal: any,
    forbidClose?: boolean,
}

function LoginModal({
    open,
    setOpen,
    setRegisterModal,
    setResetPasswordModal,
    forbidClose,
}: LoginModalType) {

    const onRegister = () => {
        setOpen(false)
        setRegisterModal(true)
    }
    const onResetPassword = () => {
        setOpen(false)
        setResetPasswordModal(true)
    }

    return (
        <AuthModalLayout
            forbidClose={forbidClose}
            open={open}
            setOpen={setOpen}
            leftImage={
                IMAGES_PREFIX + '/assets/images/signin-modal.png'
            }
        >

            <IconButton
                sx={{
                    position: 'absolute',
                    top: 21,
                    right: 16,
                    cursor: 'pointer',
                    zIndex: 10,
                }}
                onClick={() => setOpen(false)}
            >
                <CloseIcon />
            </IconButton>

            <Login
                onRegister={onRegister}
                onLoginSuccess={() =>
                    setOpen(false)
                }
                onResetPassword={onResetPassword}
            />
        </AuthModalLayout>
    );
}

export default LoginModal