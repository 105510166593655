import { yupResolver } from "@hookform/resolvers/yup"
import { object } from "yup"
import {
    confirm_password_validator,
    email_validator,
    otp_validator,
    password_validator
} from "~config/validators"

const newPasswordSchema = object().shape({
    email: email_validator,
    newPassword: password_validator,
    confirmPassword: confirm_password_validator,
    code: otp_validator,
})

export default yupResolver(newPasswordSchema)