import {
  Alert,
  Stack
} from '@mui/material'
import {
  useForm
} from 'react-hook-form'
import Button from '~components/Button/Button'
import FormProvider, {
  RHFTextField,
  RHFPhoneField
} from '~components/hook-form'
import registerSchema from '../schema/register.schema'
import {
  useRegisterMutation
} from '../services/authApi'
import {
  useRouter
} from 'next/router'
import {
  toast
} from 'react-hot-toast'
import {
  useSearchParams
} from 'next/navigation'
import {
  useSelector
} from 'react-redux'
import { getReferralId, sendTaggingRegister, clearReferralId } from '~services/tagging'
import { useCallback, useState } from 'react'



type FormValuesProps = {
  email: string
  password: string
  firstName: string
  lastName: string
  phoneNumber: string
  afterSubmit?: string
}

function AuthRegisterForm({
  onRegisterSuccess,
}: any) {

  const {
    push
  } = useRouter()

  const searchParams: any = useSearchParams()
  const id = searchParams.get('id')

  const [
    register,
    { isLoading }
  ] = useRegisterMutation()



  const [referialId, setReferialId] = useState(() => getReferralId())
  const { user } = useSelector((state: any) => state.auth)

  const order_all_submits = useSelector((state: any) => state.order)
  const {
    step1FormData: { _id: orderId1, },
    step2FormData: { _id: orderId2, },
    step3FormData: { orderId: orderId3, },
    step4FormData: { orderId: orderId4, }
  } = order_all_submits || {}
  const orderId = orderId4 || orderId3 || orderId2 || orderId1 || undefined


  let {
    step1FormData: { recipientName,

      gifterName = "",
      gifterEmail,

    },
  } = order_all_submits || {}

  // Split the full name into an array of its parts
  const nameParts = (gifterName?.trim() || "").split(/\s+/);
  let defaultLastName
  let defaultFirstName
  if (nameParts.length == 1) {
    defaultFirstName = nameParts[0];
    defaultLastName = '';
  }
  else {
    defaultLastName = nameParts.pop();
    defaultFirstName = nameParts.join(' ');
  }


  const methods = useForm<FormValuesProps>({
    resolver: registerSchema,
    defaultValues: {
      firstName: defaultFirstName,
      lastName: defaultLastName,
      email: gifterEmail,
      phoneNumber: '+1',
    }
  })

  const {
    handleSubmit,
    formState: { errors, },
  } = methods

  const handleOnPaste
    = (e: any) => {
      if (
        process.env.NODE_ENV
        === 'production'
      )
        e?.preventDefault?.()
    }

  const handleCreateAccountCallback = useCallback((data: any) => {

    (async () => {


      if (!recipientName) recipientName = 'Song Recipient'

      let userId = user?.userDto?._id

      const response = await register({
        ...data,
        roleName: 'Client',
        referralUserId: referialId,
        userId: userId || undefined,
        orderId,
        recipientName,
      })

      const { error, data: respData }: any = response || {}

      if (error)
        toast.error(
          error
            ?.data
            ?.message
        )

      if (respData) {

        // toast.success("User registered successfully")

        sendTaggingRegister({
          ...(orderId ? { transaction_id: orderId } : {})
        })

        clearReferralId()

        if (onRegisterSuccess)
          onRegisterSuccess()

        else
          push('/')
      }


    })()

  }, [
    orderId
  ])

  const handleCreateAccount = handleSubmit(handleCreateAccountCallback)

  return (
    <FormProvider
      methods={methods}
    >
      <Stack spacing={2.5}>
        {!!errors.afterSubmit &&
          <Alert
            severity="error"
          >
            {errors.afterSubmit.message}
          </Alert>
        }
        <Stack
          direction={{
            xs: 'column',
            sm: 'row'
          }}
          spacing={2}
        >
          <RHFTextField
            name="firstName"
            label="First name"
          />
          <RHFTextField
            name="lastName"
            label="Last name"
          />
        </Stack>
        <RHFPhoneField
          name="phoneNumber"
          label="Mobile phone number"
        />

        <RHFTextField
          name="email"
          label="Email address"
        />
        <RHFTextField
          name="confirmEmail"
          label="Confirm email"
          onPaste={handleOnPaste}
        />
        <RHFTextField
          name="password"
          label="Password"
        />
        <Button
          onClick={handleCreateAccount}
          loading={isLoading}
          title='Create account'
        />
      </Stack>
    </FormProvider>
  )
}

export default AuthRegisterForm