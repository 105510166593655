import {
  Box,
  Typography
} from '@mui/material'
import {
  Stack
} from '@mui/system'
import {
  useRouter
} from 'next/router'
import Back from 'public/assets/icons/back.svg'
import ForgotPassword from 'public/assets/icons/forgot-password.svg'
import {
  resetPasswordDetail
} from '~config/constants/texts'
import AuthResetPasswordForm from './components/AuthResetPasswordForm'

function ResetPassword({
  onLogin,
  ...rest
}: any) {

  const {
    push
  } = useRouter()

  const handleLogin = () => {
    if (onLogin)
      onLogin()
    else
      push('./login')
  }

  return (
    <>
      <Stack
        sx={{
          border: '0px solid red',
          maxWidth: '450px',
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ForgotPassword
          height={120}
          width={120}
        />

        <Typography
          sx={{
            fontSize: '35px !important',
            mt: 8
          }}
          variant="h3"
          paragraph
        >
          Forgot your password?
        </Typography>

        <Typography
          sx={{
            color: 'text.secondary',
            mb: 5,
            fontSize: '18px',
            width: '400px'
          }}
        >
          {resetPasswordDetail}
        </Typography>
        <AuthResetPasswordForm
          {...rest}
        />
        <Box
          onClick={handleLogin}
          sx={{
            mt: 3,
            mx: 'auto',
            alignItems: 'center',
            display: 'inline-flex',
            cursor: 'pointer',
          }}
        >
          <Back
            height={12}
            width={7}
          />
          <Typography
            sx={{
              ml: 1
            }}
          >
            Return to sign in
          </Typography>
        </Box>
      </Stack>
    </>
  )
}

export default ResetPassword