import {
  useTheme
} from '@emotion/react'
import {
  Stack,
  Typography
} from '@mui/material'
import {
  useRouter
} from 'next/router'
import useResponsive from '~hooks/useResponsive'
import AuthRegisterForm from '~screens/auth/components/AuthRegisterForm'

import { getReferralId, } from '~services/tagging'
import { useState } from 'react'
import { useModalDialogs } from '~screens/shared/modal-dialogs/modal-dialogs-provider'

function Register({
  onLogin,
  ...rest
}: any) {

  const {
    palette: {
      text,
      common,
    }
  }: any = useTheme()
  const { push } = useRouter()
  const isMobile = useResponsive('down', 'md')

  const [referialId, setReferialId] = useState(() => getReferralId())
  const { isInOrder, isInHome } = useModalDialogs();

  const handleLogin = () => {
    if (onLogin)
      onLogin()
    else
      push('./login')
  }

  return (
    <>
      <Stack
        spacing={2}
        sx={{
          mb: 5,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column', // Ensure items are vertically stacked
          alignItems: 'flex-start', // Align items to the start, affecting mobile and defaulting to left alignment
        }}
      >
        <Typography
          sx={{ pr: "20px" }}
          variant={isMobile ? "h3" : "h4"}
        >
          {isInOrder ?
            <>
              Please register to complete your order!
            </>
            :
            <>
              Great things start here
            </>
          }
        </Typography>

        {referialId &&
          <>
            <Typography
              style={{
                fontWeight: 'bold',
              }}
            >
              Claim your $5 credit gift
            </Typography>

            <Typography>
              At Songoven, we create unique custom songs for any occasion. Whether it's a birthday, anniversary, a wedding, or any other occasion that comes soon - we make sure to capture the most precious moments of your life in the form of a song.<br /><br />Sign up now and apply your $5 credit when you purchase your first song.
            </Typography>
          </>
        }

        {!isInHome &&
          <Typography
            onClick={handleLogin}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              border: '0px solid red',
              width: '250px'
            }}
          >
            Already have an account?
            <Typography
              sx={{
                ml: 1,
                color: text.link
              }}
            >
              Sign in
            </Typography>
          </Typography>
        }
      </Stack>
      <AuthRegisterForm
        {...rest}
      />
      <Typography
        component="div"
        sx={{
          color: 'text.secondaryLight',
          mt: 3,
          typography: 'caption',
          textAlign: 'center',
          fontSize: '14px'
        }}
      >
        {'By signing up, I agree to the '}
        <a
          href='/terms-of-use'
          target="_blank"
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
            color: common?.black,
          }}
          color="text.primary"
        >
          Terms of Service
        </a>
        {' and '}
        <a
          href='/privacy-policy'
          target="_blank"
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
            color: common?.black,
          }}
          color="text.primary"
        >
          Privacy Policy.
        </a>
      </Typography>
    </>
  )
}

export default Register