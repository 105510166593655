import {
  Alert,
  Box,
  Link,
  Stack
} from '@mui/material'
import NextLink from 'next/link'
import {
  useRouter
} from 'next/router'
import {
  useForm
} from 'react-hook-form'
import {
  toast
} from 'react-hot-toast'
import {
  useDispatch,
  useSelector
} from 'react-redux'
import Button from '~components/Button/Button'
import FormProvider, {
  RHFTextField
} from '~components/hook-form'
import {
  PATH_AUTH
} from '~config/routes/paths'
import {
  setPreviousAddons
} from '~screens/order/store/orderSlice'
import {
  api
} from '~services/index'
import loginSchema from '../schema/login.schema'
import {
  useLoginMutation
} from '../services/authApi'

type FormValuesProps = {
  email: string
  password: string
  afterSubmit?: string
}

function AuthLoginForm({
  onLoginSuccess,
  onResetPassword,
}: any) {

  const {
    push
  } = useRouter()

  const dispatch
    = useDispatch()

  const [
    login,
    { isLoading }
  ] = useLoginMutation()

  const methods
    = useForm<FormValuesProps>({
      resolver: loginSchema
    })

  const {
    handleSubmit,
    formState: {
      errors,
    },
  } = methods

  const {
    user
  } = useSelector((
    state: any
  ) => state.auth)

  const handleResetPassword = () => {
    if (onResetPassword)
      onResetPassword()
    else
      push(PATH_AUTH.resetPassword)

  }

  const handleLogin
    = handleSubmit(async data => {

      let userId
        = user?.userDto?._id

      const response
        = await login({
          ...data,
          userId: userId ||
            undefined,
          role: 'Client'
        })

      const {
        error,
        data: respData
      }: any = response || {}

      if (error) {

        toast.error(error?.data?.message)

        if (error?.data?.userId) {
          push(
            `/verify-email/?userId=${error
              ?.data
              ?.userId
            }`
          )
        }

      }

      if (respData) {

        if (
          respData
            ?.previousAddons
        )
          dispatch(
            setPreviousAddons(true)
          )

        dispatch(
          api.util.resetApiState()
        )

        if (onLoginSuccess)
          onLoginSuccess()
        else
          push('/')
      }
    })


  return (
    <FormProvider
      methods={methods}
    >
      <Stack
        spacing={3}
      >
        {!!errors.afterSubmit &&
          <Alert severity="error">
            {errors.afterSubmit.message}
          </Alert>
        }
        <RHFTextField
          name="email"
          label="Email address"
        />
        <RHFTextField
          name="password"
          label="Password"
        />
      </Stack>


      <Stack
        alignItems="flex-end"
        sx={{ my: 2 }}
      >
        <Box
          onClick={handleResetPassword}
          color="inherit"
          sx={{
            textDecoration:
              'underline',
            cursor: 'pointer'
          }}
        >
          Forgot password?
        </Box>
      </Stack>
      <Button
        title='Login'
        loading={isLoading}
        onClick={handleLogin}
      />

    </FormProvider>
  )
}

export default AuthLoginForm